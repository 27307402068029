import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import phone from "phone"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Link from "../link"
import { StoreConsumer } from "../../store"

const Container = styled.section``

const Location = styled.article`
  p {
    margin-bottom: 1.5em;
    opacity: 0.7;

    @media ${(props) => props.theme.smallDown} {
      margin-bottom: 0.75em;
    }
  }

  span {
    display: block;
  }
`

const Offices = ({
  data: {
    acf: { offices },
  },
}) => {
  if (!offices || !offices.length) return null

  return (
    <Container>
      <Grid>
        <Row pushY={1.5} pullY={2} mq={{ xsmallDown: { pullY: 6 } }}>
          {offices.map((office, i) => (
            <Cell
              key={i}
              size={5}
              pushX={i % 4 !== 0 && 1}
              pushY={i >= 4 && 2}
              mq={{
                xlargeDown: {
                  size: 7,
                  pushX: i % 3 !== 0 && 1,
                  pushY: i >= 3 && 2,
                },
                smallDown: {
                  size: 11,
                  pushX: i % 2 !== 0 && 1,
                  pushY: i >= 2 && 3,
                },
                xsmallDown: {
                  size: 23,
                  pushX: 0,
                  pushY: i >= 1 && 4,
                },
              }}
            >
              <Location>
                <h3 className="styled-p-leading">{office.title}</h3>

                <p className="styled-p-leading">
                  <span>{office.address}</span>
                  <span>{office.zipcode}</span>
                  <span>{office.city}</span>
                  <span>{office.country}</span>
                </p>

                {office.phone && (
                  <p className="styled-p-leading">
                    <Link
                      to={`tel:${phone(office.phone, ``, true)[0]}`}
                      className="styled-a-hover"
                    >
                      {office.phone}
                    </Link>
                  </p>
                )}

                <div className="styled-p-leading">
                  <StoreConsumer>
                    {({ translations }) => (
                      <Link
                        to={
                          office.googleMapsUrl ||
                          `https://www.google.com/maps/search/?api=1&query=${office.map}`
                        }
                        target="_blank"
                        className="styled-a"
                        dangerouslySetInnerHTML={{
                          __html: translations[`view_on_map`],
                        }}
                      />
                    )}
                  </StoreConsumer>
                </div>
              </Location>
            </Cell>
          ))}
        </Row>
      </Grid>
    </Container>
  )
}

Offices.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Offices
